import { Link } from "react-router-dom";
import { useEffect } from "react";

export const DashBoardBox = ({ users }) => {
  const metrics = [
    {
      text: "Total Users",
      to: "/users",
      img: "assets/img/bnb-coin.png",
      value: users.data?.length,
      icon: "bi bi-person",
    },
    {
      text: "Mine Code",
      value: users.data?.find((i) => i)?.userMineCode,
    },
    {
      text: "Play Quiz And Earn Code",
      value: users.data?.find((i) => i)?.dayEarnCodeNumber
    },
    
  ];

  useEffect(() => {}, [users]);
  //  console.log(users, "users")
  return (
    <>
      {metrics.map((metric, index) => (
        <div key={index} className="col-md-3 col-sm-3 col-12 more-space">
          <Link
            style={{
              color: "inherit",
              textDecoration: "none",
              cursor: "pointer",
            }}
            to={metric.to}
          >
            <div className="text balance-outer">
              <h6>
                <span className="d-block">{metric.text}</span>
              </h6>
              <div className="d-flex flex-wrap align-items-center">
                <div className="img-outer">
                  <img src={metric.img} alt="" className="img-fluid" />
                </div>
                <div className="content-outer">
                  <h5>
                    <span className="d-block"></span>
                    {metric.icon && <i className={metric.icon}></i>}
                    {metric.value}
                  </h5>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};
