import React, { useCallback, useMemo } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import image from "../Images/logo.png";
import { IoGameControllerOutline } from "react-icons/io5";
// import "./Navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const handleLogout = useCallback(() => {
    localStorage.clear();
    navigate("/login");
  });
  const json = [
    {
      icon: "bi bi-grid",
      text: "Dashboard",
      to: "/",
    },

    {
      icon: "bi bi-person",
      text: "Users",
      to: "/users",
    },
   
  ];
  const RenderNavBarData = useMemo(() => {
    return (
      <>
        {json.map((item, index) => {
          return (
            <li className="nav-item" key={index}>
              <NavLink className="nav-link" to={item.to}>
                <i className={item.icon}></i> {item.text}
              </NavLink>
            </li>
          );
        })}
      </>
    );
  });
  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabindex="-1"
        id="offcanvasScrolling"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div className="offcanvas-header">
          <div className="logo_outer">
            <a href="/">
              <img src={image} alt="" className="img-fluid" />
            </a>
          </div>
         
          {/* <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button> */}
        </div>
      
        <div className="">
          <div className="nav_side" id="navbarToggleExternalContent">
          <hr/>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {RenderNavBarData}
            </ul>
            <div className="text-center pt-3">
              <button
                onClick={handleLogout}
                type="submit"
                className="btn btn-primary w100"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      <nav className="navbar toggle_outer">
        <a href="/" className="top_logo">
          <img src={image} alt="" className="img-fluid" />
        </a>
        <div className="toggle_main">
          <a
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasScrolling"
            aria-controls="offcanvasScrolling"
          >
            <span></span>
          </a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
