import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Userslist from "./components/Userslist";
import Login from "./components/Login";
import { useNavigate } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "react-confirm-alert/src/react-confirm-alert.css";
function App() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("Auth")) {
      navigate("/login");
    }
  }, [navigate]);
  return (
    <ToastProvider>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
         <Route path="/users" element={<Userslist />} />
      </Routes>
    </ToastProvider>
  );
}

export default App;
