import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "./Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import { apiBaseUrl, jwt } from "./Baseurl";
import { confirmAlert } from "react-confirm-alert";
import { useToasts } from "react-toast-notifications";
import Loader from "./Loader";
import { configs } from "./Config";
import useFetchData from "./ApiCall";
import moment from "moment";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const Userslist = () => {
  const [show, setShow] = useState(false);
   const [userTotalTap, setUserTotalTap] = useState(0);
   const [userRemainingTap, setUserRemainingTap] = useState(0);
   const [level, setLevel] = useState(0);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  
  const { addToast } = useToasts();

  const { data: newData, loading } = useFetchData(`${apiBaseUrl}/userList`, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${jwt}`,
    },
  });
  // console.log(newData, loading);
  useEffect(() => {
    // console.log(newData);
  }, []);
  if (loading) {
    return <Loader />;
  }

  const columns = [
    {
      key: "S.R",
      text: "S.R",
      cell: (record, index) => index + 1,
    },
    {
      key: "userName",
      text: "Name",
      cell: (record) => record.userName,
    },

    {
      key: "email",
      text: "Email",
    },
    {
      key: "RegisTration Date ",
      text: "Registration Date",
      cell: (record) => (moment(record.createdAt).format("YYYY-MM-DD")),
    },
 
    {
      key: "actions",
      text: "Actions",
      cell: (record, index) => {
        // {console.log()}
        return (
          <div>
            <button
            
              className="me-2 btn_new btn btn-danger"
              onClick={() => handleDeleteRequest(record._id)}
            >
              <i class="bi bi-trash"></i>
            </button>
            <button
            
              className=" me-2 btn_new btn btn-info"
              onClick={() => handleBlock(record._id)}
            >
              {record.Block === false ? "Block" : "Unblock"}
            </button>
            <button onClick={() => handleShow(record._id)}   className="btn btn-success  btn_new">View</button>
          </div>
        );
      },
    },
  ];
  // const handleEdit = () => {};
  const blockUser = async (id) => {
    try {
      const data = await axios.post(
        `${apiBaseUrl}/Blockuserupdate`,
        {
          id: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${jwt}`,
          },
        }
      );

      addToast(`${data?.data?.message}`, {
        appearance: "success",
        autoDismiss: true,
      });
      window.location.reload();
    } catch (e) {
      addToast(e.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };
const handleDelete= async(id)=>{
  try {
    const data = await axios.post(
      `${apiBaseUrl}/DeleteUser`,
      {
        id: id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${jwt}`,
        },
      }
    );

    addToast(`${data?.data?.message}`, {
      appearance: "success",
      autoDismiss: true,
    });
    window.location.reload();
  } catch (e) {
    addToast(e.response.data.message, {
      appearance: "error",
      autoDismiss: true,
    });
  }
};

const handleDeleteRequest = async (id) => {
  confirmAlert({
    title: "Confirm to submit",
    message: "Are you sure to Delete.",
    buttons: [
      {
        label: "Yes",
        onClick: () => handleDelete(id),
      },
      {
        label: "No",
      },
    ],
  });
}
// const handleDelete =  ()
  const handleBlock = (id) => {
    // console.log("fffff" ,id)
    confirmAlert({
      title: "Confirm to submit",
      message: "Aru you sure to Block.",
      buttons: [
        {
          label: "Yes",
          onClick: () => blockUser(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
 const handleShow = (id)=>{
      const findTheRecord = newData?.data.find((i) => {
        return i._id === id;
      })
      console.log(id)
      setUserTotalTap(findTheRecord?.userToken)
      setUserRemainingTap(findTheRecord?.userDecrementBoostCoins)
      setLevel(findTheRecord?.level)
      setShow(true)
 }
  const pageChange = () => {};
  return (
    <>
      <Navbar />
     
      <div className="main_dashboard">
        <div className="body_outer">
          
          <div className="container">
          <h3 className="mb-2">User List</h3>
            <div className="card p-3">
              <ReactDatatable
                config={configs}
                records={newData?.data}
                columns={columns}
                onPageChange={pageChange.bind(this)}
              />
            </div>
           
          </div>
        </div>
      </div>
     

      <Modal  className="bg-black" show={show} onHide={handleClose}>
        <Modal.Header style={{color:"black"}} closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Total Coin: {userTotalTap}</p>
          <p>Remaining TAP: {userRemainingTap}</p>
          <p>Level: {level}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Userslist;

