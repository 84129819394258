import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { apiBaseUrl } from "./Baseurl";
import img from "../Images/logo.png";
function Login() {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (localStorage.getItem("Auth") === null) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiBaseUrl}/AdminLogin`,
        {
          email: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const { data } = response;
    
      if (data.status) {
        localStorage.setItem("Auth", data.data);
        addToast(data.message, { appearance: "success", autoDismiss: true });
        navigate("/");
      } 
    } catch (error) {
    
      addToast(error.response.data.message, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  return (
    <div className="login-bg">
      <div className="body_outer login">
        <div className="login-outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <form onSubmit={handleLogin}>
                  <div className="img-outer">
                    <img src={img} alt="" className="img-fluid" />
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
