import React, { useState, useEffect, useMemo } from "react";
import Navbar from "./Navbar";
import axios from "axios";
import { apiBaseUrl, jwt } from "./Baseurl";
import Loader from "./Loader";
import { DashBoardBox } from "./DashboardJson";
import useFetchData from "./ApiCall";
const Dashboard = () => {
  const [users, setUsers] = useState([]);
  let code = users?.data?.find((i) => i);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/userList`, {
          headers: {
            //  Authorization: `Bearer ${jwt}`,
            "Content-Type": "application/json",
          },
        });
        setUsers(response.data);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const RenderDashBoardBox = useMemo(() => {
    return (
      <>
        <DashBoardBox users={users} />
      </>
    );
  });
  return (
    <>
      <Navbar />

      <div className="main_dashboard">
        <section>
          <div className="body_outer">
            <div className="container">
              <div className="total_incoms">
                <h4>Dashboard</h4>
                <div className="row">{RenderDashBoardBox}</div>
              </div>
             
             

            
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
