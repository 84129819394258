import { Spin } from 'antd';

const Loader = () => {
 return  (
    <>
    <div className="loader-container"> {/* Wrapper div */}
    <Spin size='large' className='loader'/>
    </div>
    </>
 )

 }

 export default Loader