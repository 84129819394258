import { useState, useEffect } from "react";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const useFetchData = (url, headers) => {
  const { addToast } = useToasts()
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(url, { headers });
        setData(response.data);
      } catch (error) {

        addToast(error.message, { appearance: "error", autoDismiss: true });  
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  return { data, loading };
};

export default useFetchData;
